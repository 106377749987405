import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false
import axios from 'axios'
Vue.use(VueAxios, axios)
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
import Notifications from 'vue-notification'
Vue.use(Notifications)
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: '.',
  fractionCount: 0,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true
})
Vue.prototype.$api_key = '9e890f5d-f90f-4cab-ac8e-607cac17d86b'
Vue.prototype.$base_url = 'https://taqui.shop/api_v2/index.php/'
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
