import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/Main.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        redirect: '/eventos/1'
      },
      {
        path: '/usuarios',
        redirect: '/usuarios/1'
      },
      {
        path: '/usuarios/:page',
        name: 'Usuarios',
        component: () => import('../views/Usuarios.vue'),
      },
      {
        path: '/eventos',
        redirect: '/eventos/1'
      },
      {
        path: '/eventos/:page',
        name: 'Eventos',
        component: () => import('../views/Eventos.vue'),
      },
      {
        path: '/evento/:id_evento',
        name: 'Evento',
        component: () => import('../views/Evento.vue'),
        redirect: '/dashboard/:id_evento',
        children: [
          {
            path: '/dashboard/:id_evento',
            name: 'Dashboard',
            component: () => import('../views/Dashboard'),
          },
          {
            path: '/compras/:id_evento',
            redirect: '/compras/:id_evento/1'
          },
          {
            path: '/compras/:id_evento/:page',
            name: 'Compras',
            component: () => import('../views/Compras'),
          },
          {
            path: '/transacciones/:id_evento',
            redirect: '/transacciones/:id_evento/1'
          },
          {
            path: '/transacciones/:id_evento/:page',
            name: 'Transacciones',
            component: () => import('../views/Transacciones'),
          },
          {
            path: '/localidades/:id_evento',
            name: 'Localidades',
            component: () => import('../views/Localidades'),
          },
          {
            path: '/entradas/:id_evento',
            redirect: '/entradas/:id_evento/1'
          },
          {
            path: '/entradas/:id_evento/:page',
            name: 'Entradas',
            component: () => import('../views/Entradas'),
          },
          {
            path: '/usuarios_evento/:id_evento',
            redirect: '/usuarios_evento/:id_evento/1'
          },
          {
            path: '/usuarios_evento/:id_evento/:page',
            name: 'Usuarios_evento',
            component: () => import('../views/Usuarios_evento'),
          },
          {
            path: '/ingresos/:id_evento',
            redirect: '/ingresos/:id_evento/1'
          },
          {
            path: '/ingresos/:id_evento/:page',
            name: 'Ingresos',
            component: () => import('../views/Ingresos'),
          },
          {
            path: '/imagen/:id_evento',
            name: 'Imagen',
            component: () => import('../views/Imagen'),
          },
        ]
      },
      {
        path: '/ajustes',
        name: 'Ajustes',
        component: () => import('../views/Ajustes.vue'),
      },
    ],
    redirect: '/home'
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/Auth.vue')
  },
]
const router = new VueRouter({
  routes
})
export default router