<template>
  <div id="app">
    <router-view />
    <notifications group="foo" position="bottom right" />
  </div>
</template>
<script>
export default {
  name: "App",
  created: function () {
    var session = localStorage.getItem("sess_taqui_web");
    if (session == null || session == undefined) {
      this.$router.push("/auth");
      return false;
    }
    if (!window.indexedDB) {
      window.alert(
        "Su sistema no soporta una versión estable de la base de datos."
      );
      return false;
    }
  },
  watch: {
    $route() {
      var session = localStorage.getItem("sess_taqui_web");
      if (session == null || session == undefined) {
        this.$router.push("/auth");
        return false;
      }
    },
  },
};
</script>